import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAxios, axios)
Vue.component("font-awesome-icon", FontAwesomeIcon);

axios.defaults.baseURL = "https://contact.minds.com.tw/api";
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.get['Accepts'] = 'application/json'
// axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
Vue.prototype.$axios = axios;



import "./assets/all.scss";
library.add(fas);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
