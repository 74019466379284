<template>
  <div class="home">
    <div class="menuBox">
      <div class="logoBox">
        <img src="../assets/minds3.png" />
      </div>
      
      <div class="buttonBox">
        <el-button type="success" @click="HomeHandler"><font-awesome-icon icon="home" />公司簡介</el-button>
        <el-button type="primary" @click="productHandler"><font-awesome-icon icon="concierge-bell" />產品服務</el-button>
        <el-button type="primary" @click="agencyHandler"><font-awesome-icon icon="mobile-alt" />加值代理</el-button>
        <el-button type="primary" @click="contactHandler"><font-awesome-icon icon="phone" />聯絡我們</el-button>
      </div> 
    </div>

    <div class="bannerBox">     
      <div class="slogan">
        always find the minds
      </div> 
     
      <img src="../assets/banner.jpg"  style="width: 100%;">
    </div>
    <!-- <div class="navBox">
      <div class="titleBox">
        邁得思股份有限公司 (minds)
      </div>


    </div> -->
      <!-- <div class="mActive">
        
        <span><a href="https://www.accupass.com/event/2209020846301266036125" target="_blank" >
        【活動取消通知】
感謝大家的支持，因COVID-19疫情影響，原訂111年10月26日於竹科生活科技館-牛頓廳之
「以5G 智慧科技，加速企業ESG數位轉型研討會-新竹場」活動確定取消，造成不便，敬請見諒。</a>
        </span>
      </div>     -->

    
    <div class="contentBox">
        <div class="leftBoxTitle"><font-awesome-icon icon="angle-double-right" />公司簡介</div>
        <div class="briefBox">
          <p>邁得思 (minds) 的成立是為了讓更多大數據應用真實落地，透過商情資料的服務，提升個人及企業的價值 !</p>
          <p>邁得思 (minds) 是一間資料服務公司，提供資料採集管理、資料加值處理及資料分析應用的服務，宗旨是『解決資料策展的問題，提供商情分析的價值』。</p>
          <p>以自動化、智能化的技術，提供分析應用所需的商情資料，協助企業 mind (注意) 警訊、mind (照顧) 商機，及更了解客戶的 mind (心智)。也為個人節省 80% 的資料處理時間，得以有更多時間投入有價值的工作，及追求 mind (心靈) 的成長。</p>
          <p>~ always find the minds</p>
        </div>
        <div class="leftBoxTitle" style="margin-top:20px"><font-awesome-icon icon="angle-double-right" />服務項目</div>
        <div class="serviceList">
          <div class="lt"><font-awesome-icon icon="check-circle" />資料加值應用</div>
          <div class="lc">邁得思 (minds) 提供的不僅只是資料，而是從資料採集、資料處理、資料分析，到資料應用的 end-to-end 解決方案。BID商情站資料服務是一資料即服務（Data as a Service ）的解決方案，以API的方式提供應用分析所需的加值資料，讓您得以專注於業務創新及應用開發，將時間花在更有價值的工作。</div>
          <div class="lt"><font-awesome-icon icon="check-circle" />應用軟體開發</div>
          <div class="lc">邁得思 (minds) 具有專業的應用軟體開發經驗，包括企業網站、應用系統、演算法、資料分析等，一貫如期如質的開發與交付，深受客戶所肯定。歷經多年實務淬鍊，打造出MS智能語意分析系統、 AIM警示資訊管理系統、 BIA業務商情分析管理系統等產品，友善的操作介面及強大的分析功能，讓您得以快速導入應用。</div>
          <div class="lt"><font-awesome-icon icon="check-circle" />顧問諮詢服務</div>
          <div class="lc">邁得思 (minds) 擁有豐富的大數據處理、資料分析、應用規劃等實務經驗。能夠提供企業應用需求分析、系統架構規劃、資料處理流程等顧問諮詢服務。</div>
          <div class="lt"><font-awesome-icon icon="check-circle" />專業加值代理</div>
          <div class="lc">邁得思 (minds) 建立專職的代理經銷團隊，與ISAI原廠緊密合作，共同致力於提供專業完善的企業行動化應用解決方案，包括建置導入及售後維護服務。</div>

      </div>
    </div>
    <div class="footerBox">
      <span>Copyright © 2022 minds Corporation</span><a href="#" @click="authShow=true" >著作權聲明</a> <a href="#" @click="privShow=true">隱私權聲明</a>      
    </div>
    <el-dialog title="著作權聲明" :visible.sync="authShow" width="60%" center>
      <div class="declare">
        <p>本網站刊載之所有內容，包括但不限於文字、攝影、圖片、錄音、影像、網頁設計其他資訊，均受著作權法保護。</p>
        <p>使用本網站時必須遵守著作權法之所有相關規定。除有合理使用情形外，應取得該內容提供者之著作人書面許可授權後，方得使用，且不可任意變更、發行、播送、轉賣、重製、改作、散布、表演、展示或利用本網站之內容或服務。</p>
        <p>為供使用者便利，本網站僅提供相關網站之連結，對利用人涉及該網站內容之使用行為，本網站不負責任；本網站所提供相關連結網站之網頁或資料，均為被連結網站所提供，相關權利為該等網站或合法權利人所有，本網站不擔保其正確性、即時性或完整性。</p>
        <p>使用者於註冊時所填寫之個人資料，如：使用者名稱、姓名、性別、電子信箱、學校等，其目的在於提供使用者有關本網站之相關服務訊息。除經使用者同意、因有權機關依法要求或依法令應行配合提供之情形者，本網站絕不透漏上述個人資料予本網站以外之其他人及單位。</p>
        <p>本網站尊重他人著作權，並依「著作權聲明」及著作權法等相關規定保護著作權，任何人士不得有任何侵害他人著作權之行為。如果您認為本網站之內容侵害您之權利或有違反著作權法等情事，請聯絡我們。</p>

      </div>
    </el-dialog> 
    <el-dialog title="隱私權聲明" :visible.sync="privShow" width="75%" center>
      <div  class="declare">
        <p>邁得思股份有限公司（以下簡稱本公司）重視客戶的隱私權且遵循「個人資料保護法」之規定，您了解當您使用本服務時，您同意本網站依據「隱私權保護政策」進行您個人資料的蒐集、處理與利用。為了讓您能夠安心使用本網站的各項服務與資訊，請您詳閱下列內容： 
        </p>
        <ul>
          <li>個人資料之安全<br/>在未經客戶同意之下，本公司絕不會將客戶的個人資料提供予任何與本網站服務無關之第三人。在使用完本公司網站所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。 </li>
          <li>資料之使用與保護<br/>本網站所取得的個人資料，僅供本公司於提供本網站服務的目的使用，除非事先說明、或依照相關法律規定，本公司不會將資料提供給第三人、或移作其他目的使用。如因業務需要有必要委託第三者提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
          <br/>➣ 蒐集之目的：行銷推廣、業務諮詢、客戶管理與服務、其他經營合於營業登記項目或組織章程所定之業務。
          <br/>➣ 個人資料之類別：姓名、姓別、服務單位、職稱、連絡方式(電話、Email、地址)及其他足資識別之個人資料(識別類)。
          </li>
          <li>個人資料之修正 <br/>客戶對於其個人資料，有查詢、閱覽、補充或更正等需求時，得於週一至週五透過service@minds.com.tw客服信箱，或至本網站留言，本公司將盡速進行處理。
          </li>
          <li>網站對外的相關連結 <br/>本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站中不適用於本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
          </li>
          <li>Cookie 之使用  <br/>為了提供您最佳的服務，本網站可能會使用 Cookie 以紀錄及分析使用者行為，若您不願接受 Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie 的寫入，但可能會導至網站某些功能無法正常執行 

          </li>     
          <li>隱私權保護政策修訂 
  <br/>本網站隱私權保護政策將因應需求隨時進行修正，並將修訂或變更的內容公佈在本公司的網站上，公佈之日起七日後，該修訂或變更的內容即刻生效，本網站將不會個別通知。您如果對於本網站隱私權聲明之相關事項有任何疑問，可以利用電子郵件與本公司聯絡。
          </li>                 

        </ul>



      </div>
    </el-dialog>     

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      authShow:false,
      privShow:false,

    }
  },
  methods: {
    HomeHandler() {

    },
    productHandler() {
      this.$router.push("/product");
      // alert("Product");
    },

    agencyHandler() {
      this.$router.push("/agency");
      // alert("Product");
    }
    ,
    contactHandler() {
      this.$router.push("/contact");
      // alert("Product");
    }
  }
}
</script>

<style lang="scss" scoped>
// * {
//   outline:red solid 1px;
// }



svg {
  margin-right: 10px;
}

.declare {
  display:flex; 
  flex-direction:column; 
  align-items:flex-start;
  

  p {
    margin: 0px 0px;
  }
  ul {
    margin-top: 5px;
  }
}

.home {
  margin:0;
  padding: 0;

  .menuBox {
    display: flex;
    flex-direction: row;
    margin: 10px 20px;
    line-height: 60px;
    justify-content: space-between;
    align-items: center;    
    .logoBox {     
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30%; 
      img {
        width: 100%;
      }
    }
    .buttonBox {
      width:70%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;      
    }
  }

  .bannerBox {
    position:relative;
    .slogan {
      position:absolute; 
      left:120px; bottom:10px; 
      color: white; 
      font-weight:bold;
      font-size: 2.5rem;
      text-shadow: gray 2px 2px;
    }

  }


  .navBox {
    display: flex;
    flex-direction: row;
    padding: 10px 10%;
    justify-content: space-between;
    align-items: center;
    // outline: red solid 1px;
    .titleBox {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: "Noto Serif TC", serif;
      color: #191970;
      text-shadow: lightgrey 1px 1px;
    }
  }

  .mActive {

   
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      // outline: red 1px solid;
      border-radius: 10px;
      background-color: red;
      width: 80%;
            padding: 10px;;
      color: white;
      font-size: 1rem;
      animation: blink 3s linear infinite; 
      line-height: 1.5rem; 
      box-shadow: 3px 3px gray;  

      a:link, a:visited, a:hover, a:active {
        text-decoration: none;
        color: white;
      }
    }
    @keyframes blink {
      0%{opacity: 0;}
      50%{opacity: .5;}
      100%{opacity: 1;}      
    }
  }  

  .contentBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 20px 10%;

      .leftBoxTitle {
        text-align: left;
        font-size: 1.5rem;
      }
      .briefBox {
        margin: 15px 0px;
        padding: 0px 5%;
        text-align: left;
        p {
                font-size: 1.1rem;
                padding: 0px 0px;
                text-align: left;
                line-height: 2rem;
        }
      } 

      .serviceList {
        padding: 0px 5%;
        margin: 5px 0px;

        .lt {
          text-align: left;
          line-height: 1.5rem;
          color:#00abb9;
          font-size: 1.3rem;
          text-decoration: underline;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .lc {
          line-height: 2rem;
          margin-left:23px;
          margin-bottom: 5px;
          font-size: 1.1rem;
          text-align: left;
        }

      }
      // .lt::before  {
      //   content: "\f005";
      // }

    
  }

  .footerBox {
    line-height: 50px;
    background-color: #00abb9;
    font-size: 0.8rem;
    color: lightgray;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footerBox > a {
    margin: 0px 10px;
    font-size: 0.6rem;
  }
}

@media screen and (max-width:768px) {
  .home {
    .menuBox {
      .logoBox {
        display: none;
      }      
      .buttonBox {
        width: 100%;
        justify-content: center;
        .el-button {
          margin: 5px 5px;
          width: 150px;
        }
      }
    }
    .footerBox {
      span {
        font-size: xx-small;
      }
    }

  }

}
</style>
